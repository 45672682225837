































import Vue from 'vue'
import VueLazyload from 'vue-lazyload'
import { truncate } from 'lodash'
import he from 'he'

Vue.use(VueLazyload, {
  error: require('../assets/img/placeholder.png'),
  loading: require('../assets/img/loading.gif')
})

export default Vue.extend({
  name: 'Result' as string,

  data: function () {
    return {
      result: this.data
    }
  },

  methods: {
    unescape: function (tounescape) {
      return he.decode(tounescape)
    },

    truncate: function (phrase) {
      return truncate(phrase, {
        length: 200
      })
    },

    openLink: function (link) {
      window.open(link)
    }
  },

  props: {
    data: {
      default: null,
      type: Array,
      require: true
    },
    type: {
      default: 'recipe',
      type: String,
      require: true
    },

    language: {
      default: '',
      type: String,
      required: false
    },

    domain: {
      default: '',
      type: String,
      required: false
    }
  }
})
