











import Vue from 'vue'

export default Vue.extend({
  name: 'App' as string,

  data () {
    return {
      showSpinner: false,
      result: []
    }
  },

  props: {
    version: {
      type: String,
      required: true
    },

    language: {
      default: '',
      type: String,
      required: false
    },

    domain: {
      default: '',
      type: String,
      required: false
    }
  },

  created () {
    if (this.$workbox) {
      this.$workbox.addEventListener('waiting', () => {
        this.showUpgradeUI = true
      })
    }
  },

  methods: {
    async accept () {
      this.showUpgradeUI = false
      await this.$workbox.messageSW({ type: 'SKIP_WAITING' })
    }
  },

  watch: {
    $route () {
      this.showSpinner = true
      const self = this

      setTimeout(() => {
        self.showSpinner = false
      }, 400)
    }
  },

  metaInfo () {
    return {
      title: ' ',
      titleTemplate: '%s | Gimme Recipe',
      htmlAttrs: { lang: this.$language.current },

      meta: [
        // Generic meta tags:
        { name: 'fragment', content: '!' },
        { name: 'theme-color', content: '#4DBA87' },
        { name: 'mobile-web-app-capable', content: 'yes' },
        { name: 'msapplication-tap-highlight', content: 'no' },
        { name: 'msapplication-TileColor', content: '#4DBA87' },
        { name: 'apple-mobile-web-app-capable', content: 'yes' },

        // Twitter meta tags:
        { name: 'twitter:site', content: '' },
        { name: 'twitter:creator', content: '' },
        { name: 'twitter:card', content: 'summary_large_image' },
        { vmid: 'twitterimage', property: 'twitter:image', content: `${this.domain}/img/share.jpg` },
        { vmid: 'description', name: 'description', content: 'Gimme Recipe - A recipe generator written javascript' },

        // Facebook meta tags:
        { vmid: 'ogtype', property: 'og:type', content: 'website' },
        { vmid: 'ogurl', property: 'og:url', content: `${this.domain}/` },
        { vmid: 'ogimagewidth', property: 'og:image:height', content: '630' },
        { vmid: 'ogimageheight', property: 'og:image:width', content: '1200' },
        { vmid: 'ogtitle', property: 'og:title', itemprop: 'name', content: '' },
        { vmid: 'ogimagetype', property: 'og:image:type', content: 'image/jpeg' },
        { vmid: 'ogimage', property: 'og:image', content: `${this.domain}/img/share.jpg` },
        { vmid: 'ogdescription', property: 'og:description', content: 'Gimme Recipe - A recipe generator written javascript' }
      ]
    }
  }
})
