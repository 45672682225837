


































































import Vue from 'vue'

export default Vue.extend({
  name: 'Menu' as string,

  data: function () {
    return {
      isOpen: false
    }
  },

  props: {
    language: {
      default: '',
      type: String,
      required: false
    },

    domain: {
      default: '',
      type: String,
      required: false
    }
  },

  mounted () {},

  watch: {
    $route: function (to, from) {
      this.isOpen = false
    }
  }

})
